import React, { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import AdminLayout from '../components/Layouts/AdminLayout';
import { ApplicationState } from '../store/types';

import Route from './Route';

const AdminRoutes: React.FC = () => {
  const authStore = useSelector((state: ApplicationState) => state.auth);

  const permissions = authStore?.data?.role?.role_permissions;

  const RedirectDefault = () => <Redirect to="/" />;

  const adminRoutes = [
    {
      path: '/colaboradores',
      component: permissions?.read_employee
        ? lazy(() => import('../pages/Admin/Contributors'))
        : RedirectDefault,
    },
    {
      path: '/colaboradores/cadastro',
      component: permissions?.create_employee
        ? lazy(() => import('../pages/Admin/Contributors/ContributorDetails'))
        : RedirectDefault,
    },
    {
      path: `/colaboradores/visualizar/:id`,
      component: permissions?.read_employee
        ? lazy(() => import('../pages/Admin/Contributors/ContributorDetails'))
        : RedirectDefault,
    },
    {
      path: `/colaboradores/editar/:id`,
      component: permissions?.edit_employee
        ? lazy(() => import('../pages/Admin/Contributors/ContributorDetails'))
        : RedirectDefault,
    },

    {
      path: '/clientes',
      component: permissions?.read_client
        ? lazy(() => import('../pages/Admin/Clients'))
        : RedirectDefault,
    },
    {
      path: `/clientes/visualizar/:id`,
      component: permissions?.read_client
        ? lazy(() => import('../pages/Admin/Clients/ClientDetails'))
        : RedirectDefault,
    },
    {
      path: `/clientes/editar/:id`,
      component: permissions?.edit_client
        ? lazy(() => import('../pages/Admin/Clients/ClientDetails'))
        : RedirectDefault,
    },
    {
      path: `/clientes/cadastrar`,
      component: permissions?.create_client
        ? lazy(() => import('../pages/Admin/Clients/ClientDetails'))
        : RedirectDefault,
    },
    {
      path: `/clientes/pedido/:id`,
      component: permissions?.read_client
        ? lazy(
            () => import('../pages/Admin/Clients/ClientDetails/OrderDetails'),
          )
        : RedirectDefault,
    },
    {
      path: '/clientes/planos/:id',
      component: lazy(() => import('../pages/Admin/Clients/PlanDetails')),
    },
    {
      path: '/clientes/plano/cadastrar',
      component: lazy(() => import('../pages/Admin/Clients/PlanDetails')),
    },
    {
      path: '/clientes/plano/editar/:id',
      component: lazy(() => import('../pages/Admin/Clients/PlanDetails')),
    },
    {
      path: '/clientes/fidelidade/:id',
      component: permissions?.fidelity_access
        ? lazy(() => import('../pages/Admin/Clients/FidelityDetails'))
        : RedirectDefault,
    },

    {
      path: '/filiais',
      component: permissions?.read_branch
        ? lazy(() => import('../pages/Admin/Branches'))
        : RedirectDefault,
    },
    {
      path: '/filiais/cadastro',
      component: permissions?.create_branch
        ? lazy(() => import('../pages/Admin/Branches/BranchDetails'))
        : RedirectDefault,
    },
    {
      path: '/filiais/visualizar/:id',
      component: permissions?.read_branch
        ? lazy(() => import('../pages/Admin/Branches/BranchDetails'))
        : RedirectDefault,
    },
    {
      path: '/filiais/editar/:id',
      component: permissions?.edit_branch
        ? lazy(() => import('../pages/Admin/Branches/BranchDetails'))
        : RedirectDefault,
    },

    {
      path: '/produtos',
      component: permissions?.read_product
        ? lazy(() => import('../pages/Admin/Products'))
        : RedirectDefault,
    },
    {
      path: '/produtos/visualizar',
      component: permissions?.read_product
        ? lazy(() => import('../pages/Admin/Products/ViewProduct'))
        : RedirectDefault,
    },
    {
      path: '/produtos/editar',
      component: permissions?.edit_product
        ? lazy(() => import('../pages/Admin/Products/ViewProduct'))
        : RedirectDefault,
    },
    {
      path: '/produtos/categorias/visualizar',
      component: permissions?.read_category
        ? lazy(() => import('../pages/Admin/Products/ViewCategory'))
        : RedirectDefault,
    },
    {
      path: '/produtos/categorias/editar',
      component: permissions?.read_category
        ? lazy(
            () =>
              import(
                '../pages/Admin/Products/ViewCategory/EditImagesCategories'
              ),
          )
        : RedirectDefault,
    },
    {
      path: '/produtos/ordem-categorias/visualizar',
      component: permissions?.read_category
        ? lazy(
            () => import('../pages/Admin/Products/ViewCategory/SortCategories'),
          )
        : RedirectDefault,
    },
    {
      path: '/produtos/ordem',
      component: permissions?.read_category
        ? lazy(
            () => import('../pages/Admin/Products/ViewCategory/SortProducts'),
          )
        : RedirectDefault,
    },
    {
      path: '/produtos/combos/visualizar/:combo_id',
      component: permissions?.read_product
        ? lazy(() => import('../pages/Admin/Products/ViewCombo'))
        : RedirectDefault,
    },
    {
      path: '/produtos/combos/editar/:combo_id',
      component: permissions?.edit_product
        ? lazy(() => import('../pages/Admin/Products/ViewCombo'))
        : RedirectDefault,
    },
    {
      path: '/produtos/fidelidade/:fidelity_id',
      component: permissions?.read_category
        ? lazy(() => import('../pages/Admin/Products/ViewFidelity'))
        : RedirectDefault,
    },
    {
      path: '/produtos/campanhas/visualizar/:campaign_id',
      component: permissions?.read_campaign
        ? lazy(() => import('../pages/Admin/Products/CreateCampaign'))
        : RedirectDefault,
    },
    {
      path: '/produtos/campanhas/editar/:campaign_id',
      component: permissions?.edit_campaign
        ? lazy(() => import('../pages/Admin/Products/CreateCampaign'))
        : RedirectDefault,
    },
    {
      path: '/produtos/campanhas/criar',
      component: permissions?.create_campaign
        ? lazy(() => import('../pages/Admin/Products/CreateCampaign'))
        : RedirectDefault,
    },

    {
      path: '/pedidos',
      component: permissions?.read_order
        ? lazy(() => import('../pages/Admin/Orders'))
        : RedirectDefault,
    },
    {
      path: '/pedidos/cadastro',
      component: permissions?.create_order
        ? lazy(() => import('../pages/Admin/Orders/OrderDetails'))
        : RedirectDefault,
    },
    {
      path: `/pedidos/chamar_entregador`,
      component: lazy(() => import('../pages/Admin/Orders/CallDeliveryman')),
    },
    {
      path: `/pedidos/:id`,
      component: permissions?.read_order
        ? lazy(() => import('../pages/Admin/Orders/OrderDetails'))
        : RedirectDefault,
    },
    {
      path: `/pedidos/:id/chat/cliente_loja`,
      component: permissions?.chat_access
        ? lazy(() => import('../pages/Admin/Orders/OrderChat'))
        : RedirectDefault,
    },
    {
      path: `/pedidos/:id/chat/entregador_loja`,
      component: permissions?.chat_access
        ? lazy(() => import('../pages/Admin/Orders/OrderChat'))
        : RedirectDefault,
    },
    {
      path: `/pedidos/:id/rastrear_entrega`,
      component: permissions?.read_order
        ? lazy(() => import('../pages/Admin/Orders/OrderDeliveryMap'))
        : RedirectDefault,
    },

    {
      path: `/cupons`,
      component: permissions?.read_coupon
        ? lazy(() => import('../pages/Admin/Coupons'))
        : RedirectDefault,
    },
    {
      path: `/cupons/cadastro`,
      component: permissions?.create_coupon
        ? lazy(() => import('../pages/Admin/Coupons/CouponDetails'))
        : RedirectDefault,
    },
    {
      path: `/cupons/visualizar/:id`,
      component: permissions?.read_coupon
        ? lazy(() => import('../pages/Admin/Coupons/CouponDetails'))
        : RedirectDefault,
    },
    {
      path: `/cupons/editar/:id`,
      component: permissions?.edit_coupon
        ? lazy(() => import('../pages/Admin/Coupons/CouponDetails'))
        : RedirectDefault,
    },

    {
      path: `/ofertas`,
      component: permissions?.read_promotion
        ? lazy(() => import('../pages/Admin/Offers'))
        : RedirectDefault,
    },
    {
      path: `/ofertas/cadastro`,
      component: permissions?.create_promotion
        ? lazy(() => import('../pages/Admin/Offers/OfferDetails'))
        : RedirectDefault,
    },
    {
      path: `/ofertas/visualizar/:id`,
      component: permissions?.read_promotion
        ? lazy(() => import('../pages/Admin/Offers/OfferDetails'))
        : RedirectDefault,
    },
    {
      path: `/ofertas/editar/:id`,
      component: permissions?.edit_promotion
        ? lazy(() => import('../pages/Admin/Offers/OfferDetails'))
        : RedirectDefault,
    },

    {
      path: `/notificacoes`,
      component: permissions?.read_notification
        ? lazy(() => import('../pages/Admin/Notifications'))
        : RedirectDefault,
    },
    {
      path: `/notificacoes/cadastro`,
      component: permissions?.create_notification
        ? lazy(() => import('../pages/Admin/Notifications/NotificationDetails'))
        : RedirectDefault,
    },
    {
      path: `/notificacoes/visualizar/:id`,
      component: permissions?.read_notification
        ? lazy(() => import('../pages/Admin/Notifications/NotificationDetails'))
        : RedirectDefault,
    },
    {
      path: `/notificacoes/editar/:id`,
      component: permissions?.edit_notification
        ? lazy(() => import('../pages/Admin/Notifications/NotificationDetails'))
        : RedirectDefault,
    },

    {
      path: `/personalizacoes`,
      component: permissions?.read_advertisement
        ? lazy(() => import('../pages/Admin/Personalization'))
        : RedirectDefault,
    },
    {
      path: `/personalizacoes/cadastro`,
      component: permissions?.create_advertisement
        ? lazy(
            () => import('../pages/Admin/Personalization/AdvertisementDetails'),
          )
        : RedirectDefault,
    },
    {
      path: `/personalizacoes/visualizar-anuncio/:id`,
      component: permissions?.read_advertisement
        ? lazy(
            () => import('../pages/Admin/Personalization/AdvertisementDetails'),
          )
        : RedirectDefault,
    },
    {
      path: `/personalizacoes/editar-anuncio/:id`,
      component: permissions?.edit_advertisement
        ? lazy(
            () => import('../pages/Admin/Personalization/AdvertisementDetails'),
          )
        : RedirectDefault,
    },
    {
      path: '/fale-conosco',
      component: permissions?.suport_access
        ? lazy(() => import('../pages/Admin/ContactUs'))
        : RedirectDefault,
    },
    {
      path: '/fale-conosco/:id',
      component: permissions?.suport_access
        ? lazy(() => import('../pages/Admin/ContactUs/ContactDetails'))
        : RedirectDefault,
    },

    {
      path: `/faq`,
      component: permissions?.suport_access
        ? lazy(() => import('../pages/Admin/FAQ'))
        : RedirectDefault,
    },
    {
      path: `/faq/cadastro`,
      component: permissions?.suport_access
        ? lazy(() => import('../pages/Admin/FAQ/FAQDetailsContent'))
        : RedirectDefault,
    },
    {
      path: `/faq/detalhes/:id`,
      component: permissions?.suport_access
        ? lazy(() => import('../pages/Admin/FAQ/FAQDetailsContent'))
        : RedirectDefault,
    },

    {
      path: `/permissoes`,
      component: permissions?.read_role
        ? lazy(() => import('../pages/Admin/Permissions'))
        : RedirectDefault,
    },
    {
      path: `/permissoes/cadastro`,
      component: permissions?.create_role
        ? lazy(() => import('../pages/Admin/Permissions/PermissionsDetails'))
        : RedirectDefault,
    },
    {
      path: `/permissoes/visualizar/:id`,
      component: permissions?.read_role
        ? lazy(() => import('../pages/Admin/Permissions/PermissionsDetails'))
        : RedirectDefault,
    },
    {
      path: `/permissoes/editar/:id`,
      component: permissions?.edit_role
        ? lazy(() => import('../pages/Admin/Permissions/PermissionsDetails'))
        : RedirectDefault,
    },

    {
      path: `/taxas`,
      component: lazy(() => import('../pages/Admin/Fees')),
    },
    {
      path: `/taxas/visualizar/:id`,
      component: lazy(() => import('../pages/Admin/Fees/FeesDetails')),
    },
    {
      path: `/taxas/editar/:id`,
      component: lazy(() => import('../pages/Admin/Fees/FeesDetails')),
    },
    {
      path: `/taxas/cadastro`,
      component: lazy(() => import('../pages/Admin/Fees/FeesDetails')),
    },
    {
      path: `/pagamentos`,
      component: lazy(() => import('../pages/Admin/PaymentMethods')),
    },
    {
      path: `/pagamentos/visualizar/:id`,
      component: lazy(
        () => import('../pages/Admin/PaymentMethods/PaymentMethodsDetails'),
      ),
    },
    {
      path: `/relatorios`,
      component: lazy(() => import('../pages/Admin/Charts')),
    },
    {
      path: `/pagamentos/editar/:id`,
      component: lazy(
        () => import('../pages/Admin/PaymentMethods/PaymentMethodsDetails'),
      ),
    },
    {
      path: `/pagamentos/cadastro`,
      component: lazy(
        () => import('../pages/Admin/PaymentMethods/PaymentMethodsDetails'),
      ),
    },

    {
      path: `/perfil`,
      component: lazy(() => import('../pages/Admin/Profile')),
    },
  ];

  return (
    <AdminLayout>
      <Switch>
        {adminRoutes.map((route, id) => {
          return <Route key={String(id)} {...route} exact />;
        })}
        <Route
          path="*"
          component={() => (
            <Redirect to={permissions?.read_client ? '/clientes' : '/'} />
          )}
        />
      </Switch>
    </AdminLayout>
  );
};

export default AdminRoutes;
