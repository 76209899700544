import React, { ReactNode, useEffect, useState } from 'react';
import { BiExit } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HiOutlineMenu } from 'react-icons/hi';
import {
  AiOutlineLeft,
  AiOutlinePercentage,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { IoBarChartSharp } from 'react-icons/io5';

import { logOut } from '../../store/ducks/auth/actions';

import { ReactComponent as ClientesIcon } from '../../assets/icons/clientes.svg';
import { ReactComponent as BranchesIcon } from '../../assets/icons/branches.svg';
import { ReactComponent as ProductsIcon } from '../../assets/icons/products.svg';
import { ReactComponent as RequestsIcon } from '../../assets/icons/requests.svg';
import { ReactComponent as CouponsIcon } from '../../assets/icons/coupons.svg';
import { ReactComponent as OffersIcon } from '../../assets/icons/offers.svg';
import { ReactComponent as NotificationsIcon } from '../../assets/icons/notifications.svg';
import { ReactComponent as PersonalizationIcon } from '../../assets/icons/personalization.svg';
import { ReactComponent as ContactUsIcon } from '../../assets/icons/contact-us.svg';
import { ReactComponent as FAQIcon } from '../../assets/icons/faq.svg';
import { ReactComponent as ContributorsIcon } from '../../assets/icons/contributors.svg';
import { ReactComponent as PermissionsIcon } from '../../assets/icons/permissions.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';

import { ApplicationState } from '../../store/types';

import logo from '../../assets/logo.png';
import smallLogo from '../../assets/small-logo.png';

import { Container, MenuItems, MenuItem } from './styles';
import theme from '../../styles/theme';
import { changeTab } from '../../store/ducks/orders/actions';

interface MenuItemsProps {
  key: number;
  icon: ReactNode;
  name: string;
  link: string;
  show: boolean;
}

const listItems: MenuItemsProps[] = [
  {
    key: 1,
    icon: <ClientesIcon />,
    name: 'Clientes',
    link: '/clientes',
    show: true,
  },
  {
    key: 2,
    icon: <BranchesIcon />,
    name: 'Filiais',
    link: '/filiais',
    show: true,
  },
  {
    key: 3,
    icon: <ProductsIcon />,
    name: 'Produtos',
    link: '/produtos',
    show: true,
  },
  {
    key: 4,
    icon: <RequestsIcon />,
    name: 'Pedidos',
    link: '/pedidos',
    show: true,
  },
  {
    key: 5,
    icon: <CouponsIcon />,
    name: 'Cupons',
    link: '/cupons',
    show: true,
  },
  {
    key: 6,
    icon: <OffersIcon />,
    name: 'Ofertas',
    link: '/ofertas',
    show: true,
  },
  {
    key: 7,
    icon: <NotificationsIcon />,
    name: 'Notificações',
    link: '/notificacoes',
    show: true,
  },
  {
    key: 8,
    icon: <PersonalizationIcon />,
    name: 'Personalizações',
    link: '/personalizacoes',
    show: true,
  },
  {
    key: 9,
    icon: <ContactUsIcon />,
    name: 'Fale Conosco',
    link: '/fale-conosco',
    show: true,
  },
  {
    key: 10,
    icon: <FAQIcon />,
    name: 'FAQ',
    link: '/faq',
    show: true,
  },
  {
    key: 11,
    icon: <ContributorsIcon />,
    name: 'Colaboradores',
    link: '/colaboradores',
    show: true,
  },
  {
    key: 12,
    icon: <PermissionsIcon />,
    name: 'Permissões',
    link: '/permissoes',
    show: true,
  },
  {
    key: 13,
    icon: <AiOutlinePercentage />,
    name: 'Taxas',
    link: '/taxas',
    show: true,
  },
  {
    key: 14,
    icon: <AiOutlineCreditCard />,
    name: 'Pagamentos',
    link: '/pagamentos',
    show: true,
  },
  {
    key: 16,
    icon: <IoBarChartSharp />,
    name: 'Relatórios',
    link: '/relatorios',
    show: true,
  },
  {
    key: 15,
    icon: <ProfileIcon />,
    name: 'Perfil',
    link: '/perfil',
    show: true,
  },
];

const Siderbar: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userLogged = useSelector((state: ApplicationState) => state.auth.data);
  const authStore = useSelector((state: ApplicationState) => state.auth);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [menuItemsArray, setMenuItemsArray] =
    useState<MenuItemsProps[]>(listItems);

  useEffect(() => {
    if (authStore?.data?.role?.role_permissions) {
      const {
        read_client,
        read_branch,
        read_product,
        read_order,
        read_coupon,
        read_promotion,
        read_notification,
        read_role,
        suport_access,
        read_advertisement,
        read_employee,
      } = authStore?.data?.role?.role_permissions;
      const listMenuItems = [...menuItemsArray];

      listMenuItems[0].show = read_client;
      listMenuItems[1].show = read_branch;
      listMenuItems[2].show = read_product;
      listMenuItems[3].show = read_order;
      listMenuItems[4].show = read_coupon;
      listMenuItems[5].show = read_promotion;
      listMenuItems[6].show = read_notification;
      listMenuItems[7].show = read_advertisement;
      listMenuItems[8].show = suport_access; // Fale Conosco
      listMenuItems[9].show = suport_access; // FAQ
      listMenuItems[10].show = read_employee;
      listMenuItems[11].show = read_role;

      setMenuItemsArray(listMenuItems);
    }
  }, [authStore]);

  return (
    <Container isSidebarOpen={isSidebarOpen}>
      <a href="/">
        <img src={isSidebarOpen ? logo : smallLogo} alt="logo" />
      </a>

      {/* {isSidebarOpen && <p>{userLogged?.branch.name}</p>} */}

      <MenuItems>
        {menuItemsArray.map(
          (item) =>
            item.show && (
              <MenuItem
                key={item.key}
                to={item.link}
                open={isSidebarOpen}
                $isActiveItem={
                  location.pathname.includes(item.link) &&
                  location.pathname !== ''
                }
                title={isSidebarOpen ? '' : item.name}
                onClick={() => {
                  if (item.name.includes('Pedidos')) dispatch(changeTab(0));
                }}
              >
                <li>
                  {item.icon}
                  {isSidebarOpen && item.name}
                </li>
              </MenuItem>
            ),
        )}

        <MenuItem
          to=""
          open={isSidebarOpen}
          onClick={() => {
            dispatch(logOut());
          }}
          style={{
            marginTop: 'auto',
            width: '100%',
          }}
          title={isSidebarOpen ? '' : 'Sair'}
        >
          <li key="exitButton">
            <BiExit size="24px" style={{ transform: 'rotateY(180deg)' }} />
            {isSidebarOpen && 'Sair'}
          </li>
        </MenuItem>

        <button
          type="button"
          title={isSidebarOpen ? 'Recolher menu' : 'Expandir menu'}
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <AiOutlineLeft />
        </button>
      </MenuItems>
    </Container>
  );
};

export default Siderbar;
