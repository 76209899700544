import { PlanDTO } from '../../../dtos/auth/PlansDTO';

export enum PlanTypes {
  SELECTED_PLAN = '@auth/SELECTED_PLAN',
  CHANGE_VIEW_TYPE_PLAN = '@plans/CHANGE_VIEW_TYPE_PLAN',
  CHANGE_PLANS_PAGE = '@plans/CHANGE_PLANS_PAGE',
  CHANGE_PLANS_LIMIT_PER_PAGE = '@plans/CHANGE_PLANS_LIMIT_PER_PAGE',
  CHANGE_COUNT_PLANS = '@plans/CHANGE_COUNT_PLANS',
}

export interface PlanState {
  readonly selectedPlan?: PlanDTO | null;
  readonly viewTypePlan?: string | null; // create | view | edit
  readonly planPage: number;
  readonly planLimitPerPage: number;
  readonly countPlans: number;
}
