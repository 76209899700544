import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as PlanActions from './actions';
import { PlanState, PlanTypes } from './types';

const INITIAL_STATE: PlanState = {
  selectedPlan: null,
  viewTypePlan: 'create',
  planPage: 1,
  planLimitPerPage: 10,
  countPlans: 0,
};

type PlanReducers<Action extends AnyAction> = Reducer<PlanState, Action>;

const setSelectedPlan: PlanReducers<
  ReturnType<typeof PlanActions.setSelectedPlan>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedPlan: payload.dataPlan,
});

const changePlanPage: PlanReducers<
  ReturnType<typeof PlanActions.changePlansPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planPage: payload.planPage,
});

const changePlansLimitPerPage: PlanReducers<
  ReturnType<typeof PlanActions.changePlansLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  planLimitPerPage: payload.plansLimitPerPage,
});

const changeCountPlans: PlanReducers<
  ReturnType<typeof PlanActions.changeCountPlans>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countPlans: payload.countPlans,
});

const changeViewTypePlan: PlanReducers<
  ReturnType<typeof PlanActions.changeViewTypePlan>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypePlan: payload.viewTypePlan,
});

const reducer = createReducer<PlanState>(INITIAL_STATE)
  .handleAction(PlanTypes.SELECTED_PLAN, setSelectedPlan)
  .handleAction(PlanTypes.CHANGE_VIEW_TYPE_PLAN, changeViewTypePlan)
  .handleAction(PlanTypes.CHANGE_PLANS_PAGE, changePlanPage)
  .handleAction(PlanTypes.CHANGE_PLANS_LIMIT_PER_PAGE, changePlansLimitPerPage)
  .handleAction(PlanTypes.CHANGE_COUNT_PLANS, changeCountPlans);
export default reducer;
