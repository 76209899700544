import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ProductsDTO } from '../dtos/auth/ProductsDTO';

dayjs.extend(utc);
dayjs.extend(timezone);

export function sanitizeNumber(str: string) {
  if (str.trim()) {
    str = String(str).replace(/\D/g, '');
  }
  return str;
}

export function maskCPF(cpf: string) {
  if (cpf) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  }
  return cpf;
}

export function maskCNPJ(cnpj: string) {
  if (cnpj) {
    // cnpj = cnpj.replace(/\D/g, '');
    // cnpj = cnpj.replace(/(\d{2})/, '$1.');
    // cnpj = cnpj.replace(/(\d)(\d{3})/, '$1.$2.');
    // cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{4})/, '$1.$2.$3/');
    cnpj = cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }
  return cnpj;
}

export function zipCodeMask(zip: string) {
  let numbers = zip.replace(/\D/g, '');
  numbers = numbers.replace(/(\d{5})(\d)/, '$1-$2');
  return numbers;
}

export function getTheDayBefore() {
  const today = new Date();
  const yesterday = new Date(today.getTime());
  yesterday.setDate(today.getDate() - 1);

  return yesterday;
}

export function formatDate(date: string) {
  if (typeof date === 'string' && date.trim()) {
    const dateParse = dayjs(date).format('DD/MM/YYYY');
    date = dateParse;
  }
  return date;
}

export function formatDateReverse(date: string) {
  if (typeof date === 'string' && date.trim()) {
    const dateParse = dayjs.tz(date, 'America/Sao_Paulo').format('YYYY-MM-DD');
    date = dateParse;
  }
  return date;
}

export function formatTime(date: string) {
  let date1: Date | undefined;
  if (typeof date === 'string') {
    date1 = new Date(date);
  }
  return (
    date1 &&
    `${date1.getHours().toString().padStart(2, '0')}:${date1
      .getMinutes()
      .toString()
      .padStart(2, '0')}`
  );
}

export function cutOutTime(date: string) {
  const formattedDate = date.substring(11, 16);

  return formattedDate;
}

export function dateMask(date: string | null) {
  let date1: string | null = null;
  if (date) {
    date = date.replace(/\D/g, '');
    date = date.replace(/(\d{2})(\d)/, '$1/$2');
    date1 = date.replace(/(\d{2})(\d)/, '$1/$2');
  }
  return date1;
}

export function dateTimeMask(date: string | null) {
  let data1;
  if (typeof date === 'string' && date.trim()) {
    data1 = new Date(date);
    // data1.setUTCHours(-3);
    const day = data1.getDate().toString().padStart(2, '0');
    const month = (data1.getMonth() + 1).toString().padStart(2, '0');
    const year = data1.getFullYear();
    const hour = data1.getHours();
    const minutes = data1.getMinutes();
    date = `${day}/${month}/${year} - ${hour < 10 ? 0 : ''}${hour}:${
      minutes < 10 ? 0 : ''
    }${minutes}`;
  }
  return date;
}

export function toDate(date: string) {
  date = date.split('T') && date.split('T')[0];
  date = date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');
  return date;
}

export function fromDate(date: string) {
  date = date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
  return date;
}

export function maskBankDigit(number: string) {
  if (number) {
    number = number.replace(/\D/g, '');
    number = number.replace(/\D/g, '');
    // number = number.match(/\d{1,4}/g).join(' ');
  }
  return number;
}

export function documentMask(document: string) {
  document = document.replace(/\D/g, '');
  return document.length <= 11 ? maskCPF(document) : maskCNPJ(document);
}

export function removeDDDCountry(phone: string) {
  const formattedPhone = phone.includes('+') ? phone.substring(3) : phone;

  return formattedPhone;
}

export function phoneNumberMask(phone: string) {
  if (phone) {
    phone = phone.replace('+55', '');
    phone = phone.replace(/\D/g, '');
    phone = phone.replace(/(\d{1,2})(\d)/, '($1) $2');
    if (phone.length <= 13) {
      phone = phone.replace(/(\d{3,4})(\d)/, '$1-$2');
    } else {
      phone = phone.replace(/(\d{4,5})(\d)/, '$1-$2');
    }
  }
  return phone;
}

export function MonetaryMask(str: string | number) {
  if (str) {
    str = str.toString().replace(/\.(?![^.]*$)|[^0-9.]/g, '');
  }
  return str;
}

export const handleMonetaryFormat = (data: string | number) => {
  if (data) {
    const number = MonetaryMask(data);
    data = `R$ ${number}`;
  }
  return data;
};

export function formatMoney(money: number) {
  const formattedMoney = money?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedMoney;
}
export function formatMoneyInput(value: string) {
  let result;
  if (value && value.length > 0) {
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
    const options = { minimumFractionDigits: 2 };
    result = new Intl.NumberFormat('pt-BR', options).format(
      parseFloat(value) / 100,
    );
  } else {
    result = '';
  }

  return `R$ ${result}`;
}

export function kmMask(value: string) {
  value = value.replace(/\D/g, '');

  return `${value} km`;
}

export function onlyNumbers(text: string) {
  const numbers = text.replace(/\D/gim, '');

  return numbers;
}

export function onlyNumbersWithPoint(text: string) {
  const numbers = text.replace(/[^0-9.]/g, '');

  return numbers;
}

export function onlyPercent(text: string) {
  text = text.replace(/\D/gim, '');
  text = text.replace(/(\d{2})(\d{2})$/, '$1.$2');
  return text;
}

export function sanitizeMoney(text: string | undefined | null) {
  if (text && text?.length > 0) {
    text = text.replace('.', '').replace(',', '.').replace('R$', '');
    return parseFloat(text || '0');
  }
  return 0;
}
export function formatQuantityPages(itensPerPage: number, totalItens: number) {
  const pages = Math.floor(totalItens / itensPerPage);

  const quantityPages = totalItens % itensPerPage !== 0 ? pages + 1 : pages;

  return quantityPages;
}

export function arrayMove(
  arr: ProductsDTO[],
  product: ProductsDTO,
  new_index: number,
) {
  const fromIndex = arr.indexOf(product);
  const toIndex = new_index;

  const element = arr.splice(fromIndex, 1)[0];

  arr.splice(toIndex, 0, element);

  return arr;
}

export function maskNCM(ncm: string) {
  ncm = ncm.replace(/^(\d{4})(\d{2})(\d{2})/, '$1.$2.$3');
  return ncm;
}

export function maskAccount(account: string) {
  account = account.replace(/\D/g, '');
  account = account.replace(/(\d{1})(\d)/, '$1.$2');
  account = account.replace(/(\d{1})(\d)/, '$1.$2');
  account = account.replace(/(\d{1})(\d)/, '$1.$2');
  account = account.replace(/(\d{2})(\d)/, '$1.$2');
  account = account.replace(/(\d{4})(\d)/, '$1.$2');

  return account;
}
